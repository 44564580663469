import React from "react";
import Breadcrumb from "../components/Breadcrumb";

const Pricing = () => {
  return (
    <>
      <Breadcrumb title="Pricing" />
    </>
  );
};

export default Pricing;
