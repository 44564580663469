import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
  return (
    <>
      <section
        className="tf__breadcrumb banner"
        style={{ background: "url(images/bg/breadcrumb.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tf__breadcrum_text">
                <h1 className="text-white">{props.title}</h1>
                <ul className="page-breadcrumb">
                  <li>
                    <Link to="/" className="text_hover_animaiton">
                      Home
                    </Link>
                  </li>
                  <li>{props.title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Breadcrumb;
