import React from "react";
import { Link } from "react-router-dom";

const Subscribe = () => {
  return (
    <>
      <div className="tf__subscribe">
        <div className="tf__subscribe_overlay pt_115 xs_pt_75 pb_120 xs_pb_80">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-8 col-md-10 m-auto">
                <div className="tf__subscribe_text">
                  <h3 className="has-animation text-light">
                    Many businesses are using Trnxact to digitize their
                    Collections & Payment.
                  </h3>
                  <div className="d-flex justify-content-evenly">
                    <Link className="common_btn" to="#">
                      Contact Us
                    </Link>
                    <Link
                      className="common_btn"
                      to="#"
                      style={{ background: "#fff" }}
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
