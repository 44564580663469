import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <section
        className="tf__banner banner"
        style={{ backgroundImage: "url(images/bg/banner.jpg)" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8">
              <div className="tf__banner_text text-center">
                <h1>
                  <span className="text-white">
                    {" "}
                    Where your financial dreams
                  </span>
                  <span className="cd-headline rotate-1">
                    <span
                      className="cd-words-wrapper"
                      style={{ width: "346.65px;" }}
                    >
                      <b className="is-visible" style={{ color: "#000" }}>
                        {" "}
                        become reality
                      </b>
                    </span>
                  </span>
                </h1>
                <p style={{ color: "#000" }}>
                  The Trnxact your one-stop solution for digital banking, bill
                  payments, and recharge services. We understand the importance
                  of seamless transactions and timely bill settlements, which is
                  why we’ve designed a user-friendly platform to meet your
                  needs.
                </p>
                <ul className="p-0 m-0 mx-auto">
                  <li>
                    <Link className="common_btn" to="#">
                      Get Started
                      <i className="fa-solid fa-arrow-down-to-line"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-xl-5 col-lg-4">
              <div className="tf__banner_img">
                <div className="img">
                  <img
                    src="images/banner_img_1.jpg"
                    alt="ZYAN"
                    className="img-fluid w-100"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
