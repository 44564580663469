import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import ServicePagesSection from "../components/ServicePagesSection";

const Recharge = () => {
  return (
    <>
      <Breadcrumb title="Recharge" />
      <ServicePagesSection
        subtitle="Mobile Recharge"
        img="images/3.jpg"
        subtitle2="DTH Recharge"
        details="Whether it’s prepaid or postpaid, we’ve got you covered. Recharge your mobile phone hassle-free with us."
        img2="images/4.jpg"
        details2="Stay entertained with easy DTH recharges. Choose your provider, enter the amount, and enjoy uninterrupted TV services."
      />
    </>
  );
};

export default Recharge;
