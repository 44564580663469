import React from "react";
import Headin from "./Headin";

const faqData = [
  {
    title: "How does pricing work?",
    ans: "Our pricing is based on various factors, including the product or service you are interested in, any customization or additional features you may require, and current market rates. Prices are listed on our website or provided upon request. We strive to offer competitive and transparent pricing to ensure our customers receive fair value for their business.",
  },
  {
    title: "Are there any hidden charges or fees?",
    ans: "We strive to be transparent with our pricing, and we do not have any hidden fees or charges. However, it’s always recommended to review the terms and conditions or pricing details of any product or service you are interested in to ensure you have a clear understanding of the costs involved.",
  },
  {
    title: "Can I request a custom pricing package?",
    ans: "Yes, for certain products or services, we may offer custom pricing packages based on your specific requirements. Please contact our sales team or customer support to discuss your needs and explore possible custom pricing options.",
  },
  {
    title: "Do you offer discounts or promotions?",
    ans: "Yes, we periodically offer discounts and promotions. These may be seasonal, promotional, or based on specific events or customer loyalty. Please check our website or contact our customer support for current discounts or promotions that may apply to your purchase.",
  },
  {
    title: "Do you offer refunds or price adjustments?",
    ans: "Refunds and price adjustments may be considered on a case-by-case basis. Please refer to our refund policy or contact our customer support for more information on our refund or price adjustment processes.",
  },
];
const Faqsection = () => {
  return (
    <>
      <section
        className="tf__skills pt_115 xs_pt_75 pb_70 xs_pb_30"
        id="skills"
        style={{ background: "rgba(0, 0, 0, 0)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 m-auto mb_60 text-center">
              <Headin
                title="FAQS"
                subtitle="You have Questions ?"
                description="If your query isn’t listed below, you can directly reach out to us on support@Trnxact.com"
              />
            </div>
          </div>
          <div className="row">
            {faqData.map((data, index) => (
              <div className="col-xl-6 col-lg-6">
                <div
                  className="tf__single_skills fade_bottom mb-0"
                  style={{ paddingRight: "30px" }}
                  data-trigerid="skills"
                  data-stagger=".13"
                >
                  <h4 className="text-gold">{data.title}</h4>
                  <p className="text-white">{data.ans}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqsection;
