import React from "react";
import Headin from "./Headin";

const workData = [
  {
    title: "Sign Up",
    subtitle: " Create an account—it’s free and takes minutes.",
  },
  {
    title: "Add Billers",
    subtitle: "Link your accounts to our platform.",
  },
  {
    title: "Pay Bills and Recharge",
    subtitle: "hoose your service, enter the details, and hit “Pay.”",
  },
];

const WorkProcess = () => {
  return (
    <>
      <section
        className="tf__skills pt_115 xs_pt_75 pb_70 xs_pb_30"
        id="skills"
        style={{ backgroundImage: "url(images/bg/banner_1.jpg)" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-7">
              <img src="images/1s.png" className="w-100" alt="" srcset="" />
            </div>
            <div className="col-xl-5 col-lg-5">
              <div className="">
                <Headin
                  title="How It Works"
                  subtitle="How It Works Trnxact."
                  description="Join Trnxact today and experience the future of digital payments. Let’s simplify your financial journey together!"
                />
              </div>
              <div className="col-xl-12 col-lg-12">
                {workData.map((data, index) => (
                  <div
                    className="tf__team_skills_bar_single fade_bottom anim p-3 mb-4 c-border"
                    data-trigerid="skills"
                    data-stagger=".25"
                    key={index}
                  >
                    <div className="row align-items-center">
                      <div className="col-md-2 text-center">
                        <h1 className="text-white">
                          <b>{index}</b>
                        </h1>
                      </div>
                      <div className="col-md-10">
                        <h4 className="mb-0 pb-2 text-gold">
                          <b className="text-gold">{data.title}</b>
                        </h4>
                        <p className="mb-0 p-0">{data.subtitle}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcess;
