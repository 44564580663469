import React from "react";
import Breadcrumb from "../components/Breadcrumb";

const Refund = () => {
  return (
    <>
      <Breadcrumb title="Refund Cancellation" />
      <div class="container py-3">
        <div class="py-5 c-contyent">
          <p>Last updated: April 11, 2023</p>
          <p>
            <strong>Interpretation and Definitions</strong>
          </p>
          <p>==============================</p>
          <p>
            <strong>Interpretation</strong>
          </p>
          <p>————–</p>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <p>
            <strong>Definitions</strong>
          </p>
          <p>———–</p>
          <p>For the purposes of this Return and Refund Policy:</p>
          <p>
            “<strong>Company</strong>” (referred to as either “the Company”,
            “We”, “Us” or “Our” in this Agreement) refers to TKJS Fintech
            Private Limited Shop No 10, B Wing, Anant, Sai Apt, Diva, Thane,
            Thane- 400612, Maharashtra
          </p>
          <p>
            “<strong>Orders</strong>” mean a request by You to purchase Goods
            from Us.
          </p>
          <p>
            “<strong>Service</strong>” refers to the Website and Mobile App.
          </p>
          <p>
            “<strong>Website</strong>” refers to trnxact.com, accessible from
            https://trnxact.com
          </p>
          <p>
            “<strong>User</strong>” means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </p>
          <p>
            <strong>Order Cancellation Rights</strong>
          </p>
          <p>==============================</p>
          <p>
            The User are entitled to cancel Order within 7 days without giving
            any reason for doing so. The deadline for cancelling an Order is 7
            days from the date on which the user received the Goods or on which
            a&nbsp; third party you have appointed, who is not the carrier,
            takes possession of the product delivered.
          </p>
          <p>
            In order to exercise right of cancellation, user must inform Us of
            decision by means of a clear statement. User can inform us of your
            decision by:
          </p>
          <p>By email: info@trnxact.com</p>
          <p>By visiting this page on our website: https://trnxact.com</p>
          <p>
            We will reimburse user no later than 14 days from the day on which
            We receive the returned Goods. We will use the same means of payment
            as user used for the Order, and user will not incur any fees for
            such reimbursement. GST will not be refunded in any case.
          </p>
          <p>
            <strong>Conditions for Returns</strong>
          </p>
          <p>======================</p>
          <p>
            In order for the Goods to be eligible for a return, please make sure
            that:
          </p>
          <p>The Goods were purchased in the last 7 days</p>
          <p>The Goods are in the original packaging</p>
          <p>The following Goods cannot be returned:</p>
          <p>
            The supply of Goods made to users specifications or clearly
            personalized.
          </p>
          <p>
            The supply of Goods which according to their nature are not suitable
            to be returned, deteriorate rapidly or where the date of expiry is
            over.
          </p>
          <p>
            The supply of Goods which are not suitable for return due to health
            protection or hygiene reasons and were unsealed after delivery.
          </p>
          <p>
            The supply of Goods which are, after delivery, according to their
            nature, inseparably mixed with other items.
          </p>
          <p>
            We reserve the right to refuse returns of any merchandise that does
            not meet the above return conditions in our sole discretion.
          </p>
          <p>
            Only regular priced Goods may be refunded. Unfortunately, Goods on
            sale cannot be refunded. This exclusion may not apply to user if it
            is not permitted by applicable law.
          </p>
          <p>
            <strong>Returning Goods</strong>
          </p>
          <p>===============</p>
          <p>
            User are responsible for the cost and risk of returning the Goods to
            Us. You should send the Goods at the following address:
          </p>
          <p>
            <strong>Andheri West</strong>
          </p>
          <p>
            We cannot be held responsible for Goods damaged or lost in return
            shipment. Therefore, we recommend an insured and trackable mail
            service. We are unable to issue a refund without actual receipt of
            the Goods or proof of received return delivery.
          </p>
          <p>
            <strong>Contact Us</strong>
          </p>
          <p>———-</p>
          <p>
            If you have any questions about our Returns and Refunds Policy,
            please contact
          </p>
          <p>us:</p>
          <p>* By email: info@trnxact.com</p>
          <p>
            * By visiting this page on our website:{" "}
            <a href="https://trnxact.com">https://trnxact.com</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Refund;
