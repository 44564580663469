import React from "react";

const Headin = (props) => {
  return (
    <>
      <div className="tf__section_heading mb_40">
        <h5 className="has-animation">{props.title}</h5>
        <h2 className="has-animation text-light">{props.subtitle}</h2>
        <p className="text-light">{props.description}</p>
      </div>
    </>
  );
};

export default Headin;
