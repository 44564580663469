import React from "react";
import Breadcrumb from "../components/Breadcrumb";

const Terms = () => {
  return (
    <>
      <Breadcrumb title="Terms Conditions" />
      <div class="container py-3">
        <div class="py-3 c-contyent">
          <h2 class="display-3 colo-def"></h2>
          <p>
            This legal agreement is an electronic record in terms of the Indian
            Information Technology Act, 2000 and rules there under as applicable
            and the amended provisions about electronic records in various
            statutes as amended by the Indian Information Technology Act, 2000.
            This electronic record is generated by a computer system and does
            not require any physical or digital signatures.
          </p>
          <p>
            This legal document is published in accordance with the provisions
            of Rule 3 (1) of the Indi-an Information Technology (Intermediaries
            guidelines) Rules, 2011 and Rule 4 of the In-formation Technology
            (Reasonable security practices and procedures and sensitive
            per-sonal data or information) Rules, 2011 of Information Technology
            Act, 2000 amended through Information Technology Amendment Act, 2008
            that require publishing the Terms of Use and practices for access
            and usage of any functional website.
          </p>
          <p>
            This Website is created and operated by TKJS Fintech Private
            Limited., (hereinafter referred to as “We”, “Our”, and “Us”),
            operating under a brand name trnxact having its registered address
            at “ 1" Flr, Office no 1, Ganesh Smruti Blgd, Gaothan, Virar west
            Thane, Maharashtara 401303 ”. We intend to ensure your steady
            commitment to the usage of this Website and the services provided by
            us through our website “www.trnxact.com (“Website”).
          </p>
          <p>
            For the purpose of these Terms of Use (“Terms of Use”), wherever the
            context so requires, “We”, “Company”, “Our”, and “Us” shall mean and
            refer to the Website. “You”, “Your”, “Yourself”, “User” shall mean
            and refer to natural and legal individuals or firms who shall be
            users of this website provided by us and who is competent to enter
            into binding contracts, as per Indian laws. “Third Parties” refer to
            any website, platform or individual apart from the Users and the
            creator of this Website.
          </p>
          <p>
            <strong>General Terms.</strong>
          </p>
          <ol type="i">
            <li class="w-100">
              The headings of each section in these Terms of Use are only for
              the purpose of organizing the various provisions under these Terms
              of Use in an orderly manner and shall be used by you to interpret
              the provisions contained herein in a manner as may apply to you.
              Further, it is specifically agreed by you that the headings shall
              have legal or contractual value on your usage of the website.
            </li>
            <li class="w-100 pl-0">
              The use of this website is solely governed by these Terms of Use,
              Privacy Policy that may be uploaded on the website and any
              modifications or amendments made thereto by us from time to time,
              at our sole discretion. If you as a user continue to access and
              use this website, you are agreeing to comply with and be bound by
              the following Terms of Use and Privacy Policy.
            </li>
            <li class="w-100">
              You expressly agree and acknowledge that these Terms of Use and
              Privacy Policy are co-terminus in nature and that
              expiry/termination of either one will lead to the termination of
              the other.
            </li>
            <li class="w-100 pl-0">
              You unequivocally agree that these Terms of Use and the Privacy
              Policy constitute a legally binding agreement between us, and that
              you shall be subject to the rules, guide-lines, policies, terms,
              and conditions applicable to your use of the website, and that the
              same shall be deemed to be incorporated into these Terms of Use
              and shall be treated as part and parcel of the same. You
              acknowledge and agree that no signature or ex-press act is
              required to make these Terms of Use and the Privacy Policy binding
              on you and that your act of visiting any part of the website
              constitutes your full and final acceptance of these Terms of Use
              and the Privacy Policy.
            </li>
            <li class="w-100">
              We reserve the sole and exclusive right to amend or modify these
              Terms of Use without any prior permission or intimation to you,
              and you expressly agree that any such amendments or modifications
              shall come into effect immediately. If you do not adhere to the
              changes, You must stop using the website at once. Your continued
              use of the website will signify your acceptance of the changed
              terms.
            </li>
          </ol>
          <p>
            <strong>Registration.</strong>
          </p>
          <p>
            Registration is mandatory for all Users of the platform. The
            Registrations shall be deemed complete only upon the User’s
            authentication and verification of the User’s details through mobile
            or email verification.
          </p>
          <p>
            <strong>Eligibility</strong>
          </p>
          <ol type="a">
            <li class="w-100">
              You represent and warrant that you are competent and eligible to
              enter into legally binding agreements and of age and that you have
              the requisite authority to bind themselves to these Terms of Use
              following the Law. However, if you are a minor using this Website,
              you may do so with the consent of your legal guardian. All Acts of
              minor shall be deemed to be considered as the acts undertaken
              under the supervision of their legal guardian.
            </li>
            <li class="w-100 pl-0">
              You further represent that you will comply with these Terms of Use
              and all applicable local, state, national and international laws,
              rules and regulations.
            </li>
            <li class="w-100">
              You shall not use the Website if you are not competent to contract
              or are disqualified from doing so by any other applicable law,
              rule or regulation currently in force.
            </li>
          </ol>
          <p>
            <strong>Platform Overview</strong>
          </p>
          <p>
            The Website is operated by ‘TKJS Fintech Private Limited. ’ which
            prominently deals in providing FinTech solutions. The Website
            provides payment solutions that helps users to collect payment using
            UPI with Collect / QR / Intent using tailor-made services for the
            customers in both collections and payments. Further, the Website
            provides services related to handling the payment collections under
            UPI (QR /Intent). Also, we provide bulk pay-out services in UPI
            payout, Net-Banking Payouts, IMPS, NEFT, RTGS. The Website may also
            adopt a preference-based service for the future mode of payment
            collections methods according to the development. The
            functionalities of the Website operates following the payment and
            settlement guidelines as applicable in India to provide payment
            gateway solutions digitally and the Website will provide required
            solutions for seamless bulk transactions settlements services.
          </p>
          <p>
            <strong>Content.</strong>
          </p>
          <p>
            All text, graphics, User interfaces, visual interfaces, photographs,
            trademarks, logos, brand names, descriptions, sounds, music and
            artwork (collectively, ‘Content’), is generated/provided or based on
            information provided by the users or third parties and We has no
            control and make no guarantees regarding the quality, the accuracy,
            integrity or genuineness of such content or such other information
            provided on the Website.
          </p>
          <p>
            All the Content displayed on the Website is subject to copyright and
            shall not be reused by You (or a third party) without the prior
            written consent from Us and the copyright owner.
          </p>
          <p>
            You are solely responsible for the integrity, authenticity, quality
            and genuineness of the content provided on the Website and whilst
            feedback and comments by You can be made via the Website, we bear no
            liability whatsoever for any feedback or comments made by the other
            Users or made in respect of any of the content on the Website.
            Further, the Website reserves its right to suspend the account of
            any User for an indefinite period to be decided at the discretion of
            the Website or to terminate the account of any User who is found to
            have created or shared or submitted any content or part thereof that
            is found to be untrue/inaccurate/misleading or offensive/vulgar. You
            shall be solely responsible for making good any financial or legal
            losses incurred through the creation/sharing/submission of Content
            or part thereof that is deemed to be untrue/inaccurate/misleading.
          </p>
          <p>
            You have a personal, non-exclusive, non-transferable, revocable,
            limited privilege to access the content on the Website. You shall
            not copy, adapt, and modify any content without written permission
            from Us.
          </p>
          <p>
            <strong>Indemnity.</strong>
          </p>
          <p>
            You agree to indemnify, defend and hold harmless the Us, and our
            respective directors, officers, employees and agents (collectively,
            “Parties”), from and against any losses, liabilities, claims,
            damages, demands, costs and expenses (including legal fees and
            disbursements in connection therewith and interest chargeable
            thereon) asserted against or incurred by Us that arise out of,
            result from, or maybe payable by, any breach or non-performance of
            any representation, warranty, covenant or agreement made or
            obligation to be performed according to these Terms of Use. Further,
            you agrees to hold the Us harmless against any claims made by any
            third party due to, or arising out of, or in connection with:
          </p>
          <p>Your use of the Website.</p>
          <p>Your violation of these Terms of Use.</p>
          <p>Your violation of any rights of another.</p>
          <p>Your alleged improper conduct according to these Terms of use.</p>
          <p>Your conduct in connection with the Website.</p>
          <p>
            You agree to fully cooperate in indemnifying Us at your expense. You
            also agree not to settle with any party without consent from Us.
          </p>
          <p>
            In no event shall we be liable to compensate You or any third party
            for any special, incidental, indirect, consequential or punitive
            damages whatsoever, including those resulting from loss of use, data
            or profits, whether or not foreseeable, and whether or not You had
            been advised of the possibility of such damages, or based on any
            theory of liability, including breach of contract or warranty,
            negligence or other tortuous action, or any other claim arising out
            of or in connection with the Your use of or access to the Website
            and/or the Services or materials contained therein.
          </p>
          <p>Limitation Of Liability.</p>
          <ol type="a">
            <li class="w-100 pl-0">
              We are not responsible for any consequences arising out of the
              following events:
            </li>
          </ol>
          <p class="ml-5">
            If the Website is inoperative/non-responsive due to any connectivity
            errors associated with the internet connection such as but not
            limited to slow connectivity, no connectivity, server failure;
          </p>
          <p class="ml-5">
            If you have fed incorrect information or data or for any deletion of
            data;
          </p>
          <p class="ml-5">
            If there is an undue delay or inability to communicate through
            email;
          </p>
          <p class="ml-5">
            If there is any deficiency or defect in the Services managed by Us;
          </p>
          <p class="ml-5">
            If there is a failure in the functioning of any other service
            provided by Us.
          </p>
          <ul>
            <li class="w-100">
              The Website accepts no liability for any errors or omissions, on
              behalf of itself, or for any damage caused to You, Your
              belongings, or any third party, resulting from the use or misuse
              of the Website or any service availed of by Us through the
              Website. The service and any Content or material displayed on the
              service is provided without any guarantees, conditions or
              warranties as to its accuracy, suitability, completeness or
              reliability. We will not be liable to you for the unavailability
              or failure of the Website.
            </li>
            <li class="w-100 pl-0">
              You are to comply with all laws applicable to you or your
              activities, and with all Policies, which are hereby incorporated
              into this Terms of Use by reference.
            </li>
            <li class="w-100">
              The Website expressly excludes any liability for any loss or
              damage that was not reasonably foreseeable by the Website and
              which is incurred by you in connection with the Website, including
              loss of profits; and any loss or damage incurred by you as a
              result of your breach of these Terms of Use.
            </li>
            <li class="w-100 pl-0">
              To the fullest extent permitted by law, We shall not be liable to
              You or any other party for any loss or damage, regardless of the
              form of action or basis of any claim. You acknowledge and agree
              that your sole and exclusive remedy for any dispute with us is to
              terminate your use of the Website.
            </li>
          </ul>
          <p>
            <strong>Term</strong>
          </p>
          <ol type="a">
            <li class="w-100">
              These Terms of Use shall continue to form a valid and binding
              contract between us and shall continue to be in full force and
              effect until you continue to access and use the Websites.
            </li>
            <li class="w-100 pl-0">
              You may terminate your use of the Website at any time.
            </li>
            <li class="w-100">
              We may terminate these Terms of Use and close your account at any
              time without notice and/or suspend or terminate Your access to the
              Website at any time and for any reason, if any discrepancy or
              legal issue arises.
            </li>
            <li class="w-100 pl-0">
              Such suspension or termination shall not limit our right to take
              any other action against you that we consider appropriate.
            </li>
            <li class="w-100">
              It is also hereby declared that we may discontinue the Services
              and Websites without any prior notice.
            </li>
          </ol>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Termination.</strong>
          </p>
          <ol type="a">
            <li class="w-100">
              We reserve the right, in its sole discretion, to unilaterally
              terminate Your access to the Website, or any portion thereof, at
              any time, without notice or cause.
            </li>
            <li class="w-100 pl-0">
              We also reserve the universal right to deny access to You, to
              any/all of are on its website without any prior notice/explanation
              to protect the interests of the Website and/or other Users to the
              Website.
            </li>
            <li class="w-100">
              We also reserve the universal right to deny access to You, to
              any/all of are on its website without any prior notice/explanation
              to protect the interests of the Website and/or other Users to the
              Website.
            </li>
            <li class="w-100 pl-0">
              We reserve the right to limit, deny or create different access to
              the Website and its features concerning different Users, or to
              change any of the features or introduce new features without prior
              notice.
            </li>
            <li class="w-100">
              You shall continue to be bound by these Terms of use, and it is
              expressly agreed to by You that You shall not have the right to
              terminate these Terms of Use till the expiry of the same.
            </li>
            <li class="w-100 pl-0">
              <p>
                At any point in time if Company discovers that Sub Merchant is
                dealing / trading / offering services from the Banned Merchant
                as detailed in Annexure “B” of Service Agreement then Company
                will take appropriate action to comply as per the regulatory
                requirements on Immediate basis.
              </p>
              <p>
                The termination of this Agreement shall not affect the rights or
                liabilities of either Party incurred prior to such termination.
                In addition, any act performed during the term of this Agreement
                which may result in a dispute post termination or any provision
                expressed to survive this Agreement or to be effective on
                termination or the obligations set out in this Clause shall
                remain in full force and effect notwithstanding termination.
              </p>
              <p>
                All materials, documentation, instruction manuals, guidelines,
                letters and writings and any other confidential materials issued
                by Company from time to time in respect of this Agreement,
                whether in respect of the utilization of API or otherwise shall
                be returned by Sub Merchant to Company upon termination.
              </p>
              <p>
                In case of a “charge back”, repudiation of any transaction by a
                Customer, or a request for refund by any Customer for any reason
                whatsoever, Company shall be entitled to cancel Authorization
                and refuse to make any payments to Sub Merchant. If there are
                insufficient funds available in the Sub Merchant’s Account
                maintained with Company; the Sub Merchant shall on receipt of
                the claim from Company undertakes forthwith to pay to Company,
                the amount of the refund / charge back / repudiation to the
                extent to which such funds proves inadequate.
              </p>
              <p>
                In case of Fraud Chargeback occurrence more than once and having
                aggregate value of more than Rs 50,000, Company reserves the
                right to forfeit the Security Deposit given by Sub Merchant
                without any prior intimation.
              </p>
              <p>
                In case the Sub Merchants uses trnxact UPI Services for any
                other category / business other than mentioned during on
                boarding, then the amount collected for the day / period will be
                blocked and will not be released and the security deposit (if
                any) will also stand forfeited without any prior intimation.
              </p>
              <p>
                During the period of agreement, if Master Merchant or Bank finds
                out that the UPI APIs are not being used for the purpose
                intended i.e., the UPI APIs are not being used in the app for
                which registration was done, then Master Merchant reserves the
                right to forfeit the security deposit and any funds that has
                been collected will also be forfeited without prior notice.
              </p>
              <p>
                The security deposit will be refunded to Sub Merchant after 3
                months from the date of termination of this service agreement.
                In case there is any chargeback during these 3 months, then the
                chargeback amount will be deducted from the security deposit.
              </p>
            </li>
          </ol>
          <p>
            <strong>Communication.</strong>
          </p>
          <p>
            By using this Website and providing Your identity and contact
            information to Us through the Website, You agree and consent to
            receive calls, e-mails or SMS from Us and/or any of its
            representatives at any time.
          </p>
          <p>
            You can report to info@trnxact.com if you find any discrepancy with
            regard to Website or content-related information and we will take
            necessary action after an investigation. The response with
            resolution (if any issues found) shall be dependent on the time is
            taken for investigation.
          </p>
          <p>
            You expressly agree that notwithstanding anything contained herein
            above, it may be contacted by Us relating to any services availed by
            You on the Website or anything pursuant thereto and You agree to
            indemnify Us from any harassment claims. It is expressly agreed to
            by Us that any information shared by Us shall be governed by the
            Privacy Policy.
          </p>
          <p>User Obligations And Formal Undertakings As To Conduct.</p>
          <p>
            You agree and acknowledges that you are a restricted user of this
            Website and you:
          </p>
          <p>
            Agree to provide genuine credentials during the process whenever
            required on the Website. You shall not use a fictitious identity to
            register. We are not liable if you have provided incorrect
            information.
          </p>
          <p>
            Agree to ensure the Name, Email address, Address, Mobile number and
            any such other information that may be provided during account
            registration is valid at all times and shall keep your information
            accurate and up to date.
          </p>
          <p>
            You agree that You are solely responsible for maintaining the
            confidentiality of your account password. You agree to notify us
            immediately of any unauthorized use of your account. We reserve the
            right to close your account at any time for any or no reason.
          </p>
          <p>
            Authorize the Website to use, store or otherwise process certain
            personal information and all published Content, responses,
            locations, User comments, reviews and ratings for personalization of
            Services, marketing and promotional purposes and for optimisation of
            User-related options and Services.
          </p>
          <p>
            Understand and agree that, to the fullest extent permissible by law,
            the Website or any of their affiliates or their respective officers,
            directors, employees, agents, licensors, representatives,
            operational service providers, advertisers or suppliers shall not be
            liable for any loss or damage, of any kind, direct or indirect, in
            connection with or arising from the use of the Website or this terms
            of use, including, but not limited to, compensatory, consequential,
            incidental, indirect, special or punitive damages.
          </p>
          <p>
            Are bound not to cut, copy, modify, recreate, reverse engineer,
            distribute, disseminate, post, publish or create derivative works
            from, transfer, or sell any information or obtained from the
            Website. Any such use/limited use of the Website will only be
            allowed with the prior express written permission.
          </p>
          <p>
            Agree not to access (or attempt to access) the Website and/or the
            materials or Services by any means other than through the interface
            provided by the Website. The use of deep-link, robot, spider or
            other automatic devices, program, algorithm or methodology, or any
            similar or equivalent manual process, to access, acquire, copy or
            monitor any portion of the Website or its content, or in any way
            reproduce or circumvent the navigational structure or presentation
            of the Website, materials or any content, or to obtain or attempt to
            obtain any materials, documents or information through any means not
            specifically made available through the Website will lead to
            suspension or termination of your access to the Website. We disclaim
            any liabilities arising concerning such offensive content on the
            Website.
          </p>
          <p>
            Expressly agree and acknowledge that the Content generated by the
            Users and displayed on the Website is not owned by Us and that We
            are in no way responsible for the content of the same. You may,
            however, report any offensive or objectionable content, which We may
            then remove from the Website, at our sole discretion.
          </p>
          <p>You further undertake not to:</p>
          <p>
            Engage in any activity that interferes with or disrupts access to
            the Website or the services provided therein (or the servers and
            networks which are connected to the Website);
          </p>
          <p>
            Impersonate any person or entity, or falsely state or otherwise
            misrepresent his/her affiliation with a person or entity;
          </p>
          <p>
            Probe, scan or test the vulnerability of the Website or any network
            connected to the Website, nor breach the security or authentication
            measures on the Website or any network connected to the Website. The
            User may not reverse look-up, trace or seek to trace any information
            relating to any other User of, or visitor to, the Website, or any
            other viewer of the Website, including any User account maintained
            on the Website not operated/managed by the User, or exploit the
            Website or information made available or offered by or through the
            Website, in any manner;
          </p>
          <p>
            Disrupt or interfere with the security of, or otherwise cause harm
            to, the Website, systems resources, accounts, passwords, servers, or
            networks connected to or accessible through the Website or any
            affiliated or linked Websites.
          </p>
          <p>
            Use the Website or any material or content therein for any purpose
            that is unlawful or prohibited by these Terms of Use, or to solicit
            the performance of any illegal activity or other activity which
            infringes the rights of this Website or any other third party (s);
          </p>
          <p>
            Violate any code of conduct or guideline which may apply for or to
            any particular service offered on the Website;
          </p>
          <p>
            Violate any applicable laws, rules or regulations currently in force
            within or outside India;
          </p>
          <p>
            Violate any portion of these Terms of Use or the Privacy Policy,
            including but not limited to any applicable additional terms of the
            Website contained herein or else-where, whether made by amendment,
            modification, or otherwise;
          </p>
          <p>
            Commit any act that causes Us to lose (in whole or in part) the
            Services of its Internet Establishment (“ISP”) or in any manner
            disrupts the Services of any other supplier/service provider of the
            Website;
          </p>
          <p>
            You hereby expressly authorize Us to disclose any and all
            information relating to You in our possession to law enforcement or
            other government officials, as We may in our sole discretion,
            believe necessary or appropriate in connection with the
            investigation and/or resolution of possible crimes, especially those
            involve personal injury and theft/infringement of intellectual
            property. You further understand that the Website might be directed
            to disclose any information (including the identity of persons
            providing information or materials on the Website) as necessary to
            satisfy any judicial Order, law, regulation or valid governmental
            request.
          </p>
          <p>
            By indicating Your acceptance to use any services offered on the
            Website, You are obligated to complete such transactions after
            making payment. You shall be prohibited from indicating their
            acceptance to avail services where the transactions have remained
            incomplete.
          </p>
          <p>
            You agree to use the services provided by Us, our affiliates,
            consultants and contracted companies, for lawful purposes only.
          </p>
          <p>
            You agree to provide authentic and true information. We reserve the
            right to confirm and validate the information and other details
            provided by You at any point in time. If upon confirmation such
            details are found to be false, not to be true (wholly or partly), We
            shall in our sole discretion reject the registration and debar You
            from using the Website without prior intimation whatsoever.
          </p>
          <p>
            You agree not to post any material on the Website that is
            defamatory, offensive, obscene, indecent, abusive, or needlessly
            distressful or advertising any goods or services. More specifically,
            You agree not to host, display, upload, update, publish, modify,
            transmit, or in any manner share any information that:
          </p>
          <p>Belongs to another person and to which you have no right to;</p>
          <p>
            Is grossly harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, paedophilic, libellous, invasive of another’s privacy,
            hateful, or racially, ethnically objectionable, disparaging,
            relating or encouraging money laundering or gambling, or otherwise
            unlawful in any manner whatever;
          </p>
          <p>Is in any way harmful to minors;</p>
          <p>
            Infringes any patent, trademark, copyright or other proprietary
            rights;
          </p>
          <p>Violates any law for the time being in force;</p>
          <p>
            Deceives or misleads the addressee about the origin of such messages
            or communicates any information which is grossly offensive or
            menacing;
          </p>
          <p>
            Abuse, harass, threaten, defame, disillusion, erode, abrogate,
            demean or otherwise violate the legal rights of others;
          </p>
          <p>
            Impersonate any person or entity, or falsely state or otherwise
            misrepresent Your affiliation with a person or entity;
          </p>
          <p>
            Publish, post, disseminate, any grossly harmful information,
            harassing, blasphemous, defamatory, obscene, pornographic,
            paedophilic, libellous, invasive of another’s privacy, hateful, or
            racially, ethnically objectionable, disparaging, relating or
            encouraging money laundering or gambling, or otherwise unlawful in
            any manner whatever; or unlawfully threatening or unlawfully
            harassing including but not limited to “indecent representation of
            women” within the meaning of the Indecent Representation of Women
            (Prohibition) Act, 1986;
          </p>
          <p>
            Threatens the unity, integrity, defence, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting any other
            nation.
          </p>
          <p>Refunds.</p>
          <ol type="a">
            <li class="w-100">
              In the event of disputed payments, all refunds shall be made to
              the original method in which the disputed transaction occurred. In
              the event that the user raises a Refund Request to with or fails
              to contest such Disputed Transaction within the aforesaid 7
              working days or contests Disputed Transaction without providing
              supporting documentation to us, payment service providers, Card
              Payment Network and/ or issuing institution’s satisfaction, we
              shall be entitled to recover the Refund Monies from credits
              subsequently made to the escrow account with respect to payments
              made by the seller’s buyers.
            </li>
            <li class="w-100 pl-0">
              All payments with respect to refunds shall be solely your
              responsibility and we shall not be liable for any claims,
              disputes, penalties which may arise in connection with such
              refunds to you or the Customer. You shall indemnify us in respect
              of any claims, disputes, penalties, costs, and expenses arising
              directly or indirectly in relation to refunds and Disputes for all
              Transactions.
            </li>
            <li class="w-100">
              The User hereby agrees and acknowledged by the parties that the
              Fees charged by us in respect of the Disputed Transaction will not
              be refunded or repaid by us to the user, buyer or any other
              person.
            </li>
          </ol>
          <p>
            <strong>Suspension Of User Access And Activity.</strong>
          </p>
          <p>
            Not with standing other legal remedies that may be available, we may
            in our sole discretion, limit your access and/or activity by
            immediately removing your access credentials either temporarily or
            indefinitely, or suspend/terminate your association with the
            Website, and/or refuse to the usage of the Website, without being
            required to provide you with notice or cause:
          </p>
          <p>
            If you are in breach of any of these Terms of Use or the Privacy
            Policy;
          </p>
          <p>
            If you have provided wrong, inaccurate, incomplete or incorrect
            information;
          </p>
          <p>
            If your actions may cause any harm, damage or loss to the other
            Users or Us, at our sole discretion.
          </p>
          <p>
            <strong>Intellectual Property Rights.</strong>
          </p>
          <p>
            Unless expressly agreed to in writing, nothing contained herein
            shall give you a right to use any of the trade names, trademarks,
            service marks, logos, domain names, information, questions, answers,
            solutions, reports and other distinctive brand features, save
            according to the provisions of these Terms of Use that shall be
            available on the website. All logos, trademarks, brand names,
            service marks, domain names, including material, designs, and
            graphics created by and developed by either the website or such
            other third party and other distinctive brand features of the
            website are the property of the website or the respective copyright
            or trademark owner. Furthermore, concerning the website, we shall be
            the exclusive owner of all the designs, graphics and the like,
            related to the Website.
          </p>
          <p>
            You shall not use any of the intellectual property displayed on the
            Website in any manner that is likely to cause confusion among
            existing or prospective users of the website, or that in any manner
            disparages or discredits the website, to be determined in the sole
            discretion.
          </p>
          <p>
            You are aware all intellectual property, including but not limited
            to copyrights, relating to said services resides with the owners,
            and that at no point does any such intellectual property stand
            transferred from the aforementioned creators. You are aware that we
            merely provide the Website through which You can communicate with
            other users and the Website does not own any of the intellectual
            property relating to the independent content displayed on the
            Website, apart from created graphics and specified content.
          </p>
          <p>
            You are further aware that any reproduction or infringement of the
            intellectual property of the aforementioned owners by You will
            result in legal action being initiated against You by the respective
            owners of the intellectual property so reproduced/infringed upon. It
            is agreed to by You that the contents of this section shall survive
            even after the termination or expiry of these Terms of Use and/or
            Privacy Policy.
          </p>
          <p>
            <strong>Disclaimer Of Warranties And Liabilities</strong>
          </p>
          <ol>
            <li class="w-100">
              You further agree and undertake that you are accessing the Website
              at your sole risk and are that you are using the best and prudent
              judgment before availing of any features on the Website or
              accessing/using any information displayed thereon.
            </li>
            <li class="w-100 pl-0">
              You agree that any kind of information, resources, activities,
              recommendations obtained/availed from the Website, written or
              oral, will not create any warranty and we disclaim all liabilities
              resulting from these.
            </li>
            <li class="w-100">
              We do not guarantee that the features and content contained in the
              Website will be uninterrupted or error-free, or that the Website
              or its server will be free of viruses or other harmful components,
              and You hereby expressly accepts any associated risks involved
              with your use of the Website.
            </li>
            <li class="w-100 pl-0">
              It is further agreed to by You that the contents of this section
              shall survive even after the termination or expiry of the Terms of
              Use and/or Privacy Policy.
            </li>
          </ol>
          <p>
            <strong>Force Majeure.</strong>
          </p>
          <p>
            We will not be liable for damages for any delay or failure to
            perform our obligations here-under if such delay or failure is due
            to cause beyond our control or without its fault or negligence, due
            to Force Majeure events including but not limited to acts of war,
            acts of God, earthquake, riot, fire, festive activities sabotage,
            labour shortage or dispute, internet interruption, technical
            failure, breakage of sea cable, hacking, piracy, cheating, illegal
            or unauthorized.
          </p>
          <p>
            <strong>Dispute Resolution And Jurisdiction.</strong>
          </p>
          <p>
            It is expressly agreed to by you hereto that the formation,
            interpretation, and performance of these Terms of Use and any
            disputes arising therefrom will be resolved through Arbitration. It
            is further agreed to by you that the contents of this section shall
            survive even after the termination or expiry of the Terms of Use
            and/or Privacy Policy.
          </p>
          <p>
            Arbitration: In the event that the Parties are unable to amicably
            resolve a dispute, the said dispute will be referred to arbitration
            by a sole arbitrator to be appointed by us, and the award passed by
            such sole arbitrator will be valid and binding on all parties. All
            cost of the Arbitration shall be borne solely in accordance with the
            Arbitrators award. The arbitration shall be conducted in English,
            and the seat of Arbitration shall be the city of Mumbai, India.
          </p>
          <p>
            You expressly agree that the Terms of Use, Privacy Policy and any
            other agreements entered into between the Parties are governed by
            the laws, rules, and regulations of India.
          </p>
          <p>
            <strong>Miscellaneous Provisions</strong>
          </p>
          <p>
            a. Entire Agreement: These Terms of Use, read with the Privacy
            Policy, form the complete and final contract between us with respect
            to the subject matter hereof and supersedes all other
            communications, representations, and agreements (whether oral,
            written or otherwise) relating thereto.
          </p>
          <p>
            b. Waiver: The failure at any time to require performance of any
            provision of these Terms of Use shall in no manner affect our right
            at a later time to enforce the same. No waiver by us of any breach
            of these Terms of Use, whether by conduct or other-wise, in any one
            or more instances, shall be deemed to be or construed as a further
            or continuing waiver of any such breach, or a waiver of any other
            breach of these Terms of Use.
          </p>
          <p>
            c. Severability: If any provision/clause of these Terms of Use is
            held to be invalid, illegal or unenforceable by any court or
            authority of competent jurisdiction, the validity, legality, and
            enforceability of the remaining provisions/clauses of these Terms of
            Use shall in no way be affected or impaired thereby, and each such
            provision/clause of these Terms of Use shall be valid and
            enforceable to the fullest extent permitted by law. In such case,
            these Terms of Use shall be reformed to the minimum extent necessary
            to correct any invalidity, illegality or unenforceability, while
            preserving to the maximum extent the original rights, intentions and
            commercial expectations of the Parties hereto, as expressed herein.
          </p>
          <p>
            d. Contact Us: If you have any questions about these Terms of Use,
            the practices of the website, or your experience, you can contact us
            by emailing at{" "}
            <a href="mailto:info@trnxact.com">info@trnxact.com</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Terms;
