import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { FiAlignJustify } from "react-icons/fi";

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg main_menu">
        <div className="container">
          <a className="navbar-brand" href="index.html">
            <img src="images/logo.png" alt="ZYAN" className="img-fluid w-100" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ color: "#fff" }}
          >
            <FiAlignJustify />
            {/* <FiX /> */}
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link text_hover_animaiton" to="/">
                  <div className="text-nave menu-text">
                    <div className="text-nave">H</div>
                    <div className="text-nave">o</div>
                    <div className="text-nave">m</div>
                    <div className="text-nave">e</div>
                  </div>{" "}
                  <i className="far fa-angle-down"></i>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link text_hover_animaiton d-flex"
                  to="/about"
                >
                  <div className="text-nave menu-text">
                    <div className="text-nave">a</div>
                    <div className="text-nave">b</div>
                    <div className="text-nave">o</div>
                    <div className="text-nave">u</div>
                    <div className="text-nave">t</div>
                  </div>
                  <div
                    className="text-nave menu-text"
                    style={{ marginLeft: "5px" }}
                  >
                    <div className="text-nave">u</div>
                    <div className="text-nave">s</div>
                  </div>
                </NavLink>
              </li>
              <li className="nav-item d-none">
                <NavLink
                  className="nav-link text_hover_animaiton"
                  to="/pricing"
                >
                  <div className="text-nave menu-text">
                    <div className="text-nave">P</div>
                    <div className="text-nave">r</div>
                    <div className="text-nave">i</div>
                    <div className="text-nave">c</div>
                    <div className="text-nave">i</div>
                    <div className="text-nave">n</div>
                    <div className="text-nave">g</div>
                  </div>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link text_hover_animaiton" to="#">
                  <div className="text-nave menu-text">
                    <div className="text-nave">P</div>
                    <div className="text-nave">r</div>
                    <div className="text-nave">o</div>
                    <div className="text-nave">d</div>
                    <div className="text-nave">u</div>
                    <div className="text-nave">c</div>
                    <div className="text-nave">t</div>
                    <div className="text-nave">s</div>
                  </div>{" "}
                  <i className="far fa-angle-down"></i>
                </NavLink>
                <ul className="droap_menu d-none d-md-block">
                  <li>
                    <Link to="/recharge"> Recharge</Link>
                  </li>
                  <li>
                    <Link to="/bill_payment">Bill Payment</Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item d-md-none">
                <NavLink
                  className="nav-link text_hover_animaiton mob-sub"
                  to="/recharge"
                >
                  {" "}
                  Recharge
                </NavLink>
              </li>
              <li className="nav-item d-md-none">
                <NavLink
                  className="nav-link text_hover_animaiton mob-sub"
                  to="/bill_payment"
                >
                  Bill Payment
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link text_hover_animaiton d-flex"
                  to="/contact"
                >
                  <div className="text-nave menu-text">
                    <div className="text-nave">c</div>
                    <div className="text-nave">o</div>
                    <div className="text-nave">n</div>
                    <div className="text-nave">t</div>
                    <div className="text-nave">a</div>
                    <div className="text-nave">c</div>
                    <div className="text-nave">t</div>
                  </div>
                  <div
                    className="text-nave menu-text"
                    style={{ marginLeft: "5px" }}
                  >
                    <div className="text-nave">u</div>
                    <div className="text-nave">s</div>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default Header;
