import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import AboutSection from "../components/AboutSection";
import ServiceSection from "../components/ServiceSection";
import Faqsection from "../components/Faqsection";

const AboutUs = () => {
  return (
    <>
      <Breadcrumb title="About Us" />
      <AboutSection />
      <ServiceSection />
      <Faqsection />
    </>
  );
};

export default AboutUs;
