import React from "react";
import Headin from "./Headin";
const data = [
  {
    id: 1,
    img: "images/icon/1.png",
    title: "Wide Network",
    description:
      " We’re directly connected to all major operators, ensuring you have access to a single wallet for all your mobile  and DTH recharges.",
  },
  {
    id: 2,
    img: "images/icon/2.png",
    title: "Security First",
    description:
      "Your data security is our priority. Rest assured that your personal information is safe with us. User-Friendly",
  },
  {
    id: 3,
    img: "images/icon/3.png",
    title: "Interface",
    description:
      "Our intuitive platform makes transactions quick and straightforward. No technical jargon—just smooth sailing.",
  },
];

const Choose = () => {
  return (
    <>
      <section
        className="tf__service pt_130 xs_pt_80 pb_80"
        id="service"
        style={{ background: "#fff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5 m-auto text-center">
              <div className="tf__section_heading mb_40">
                <h5 className="has-animation">Why Choose Us?</h5>
                <h2 className="has-animation text-dark ">Why Choose Trnxact</h2>
                <p className="text-dark">
                  Trnxact offer various products which is being offered by
                  traditional payment gateways but directly with Bank
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {data.map((item, index) => (
              <div class="col-xl-4 col-md-4" key={index}>
                <div
                  class="tf__design_counter fade_left"
                  data-trigerid="counter"
                >
                  <span class="icon">
                    <img
                      src={item.img}
                      alt="counter"
                      class="svg img-fluid w-100"
                    />
                  </span>
                  <h3 style={{ color: "#ffba07" }}>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Choose;
