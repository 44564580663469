import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer
        className="footer"
        id="footer"
        style={{
          background: "#09101a",
        }}
      >
        <div className="footer-container">
          <div className="pt_120 xs_pt_80 sm_pt_80">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div
                    className="tf__footer_content fade_right"
                    data-trigerid="footer"
                    data-stagger=".25"
                    style={{
                      translate: "none",
                      rotate: "none",
                      scale: "none",
                      transform: "translate(0px)",
                      opacity: "1",
                    }}
                  >
                    <div className="text">
                      <h3 className="text-gold">Quick links</h3>
                      <ul className="p-0 px-2">
                        <li>
                          <Link
                            to="/"
                            className="text_hover_animaiton d-flex tex-animate"
                          >
                            <div className="text-nave menu-text">
                              <div className="text-nave">H</div>
                              <div className="text-nave">O</div>
                              <div className="text-nave">M</div>
                              <div className="text-nave">E</div>
                            </div>
                          </Link>
                        </li>
                        {/* <li className="mt-3">
                          <Link
                            to="/about"
                            className="text_hover_animaiton d-flex tex-animate"
                          >
                            <div className="text-nave menu-text">
                              <div className="text-nave">P</div>
                              <div className="text-nave">R</div>
                              <div className="text-nave">I</div>
                              <div className="text-nave">C</div>
                              <div className="text-nave">I</div>
                              <div className="text-nave">N</div>
                              <div className="text-nave">G</div>
                            </div>
                          </Link>
                        </li> */}

                        <li className="mt-3">
                          <Link
                            to="/about"
                            className="text_hover_animaiton d-flex tex-animate"
                          >
                            <div className="text-nave menu-text">
                              <div className="text-nave">A</div>
                              <div className="text-nave">B</div>
                              <div className="text-nave">O</div>
                              <div className="text-nave">U</div>
                              <div className="text-nave">T</div>
                            </div>
                            <div
                              className="text-nave menu-text"
                              style={{ marginLeft: "5px" }}
                            >
                              <div className="text-nave">U</div>
                              <div className="text-nave">S</div>
                            </div>
                          </Link>
                        </li>
                        <li className="mt-3">
                          <Link
                            to="/contact"
                            className="text_hover_animaiton d-flex tex-animate"
                          >
                            <div className="text-nave menu-text">
                              <div className="text-nave">C</div>
                              <div className="text-nave">O</div>
                              <div className="text-nave">N</div>
                              <div className="text-nave">T</div>
                              <div className="text-nave">A</div>
                              <div className="text-nave">C</div>
                              <div className="text-nave">T</div>
                            </div>
                            <div
                              className="text-nave menu-text"
                              style={{ marginLeft: "5px" }}
                            >
                              <div className="text-nave">U</div>
                              <div className="text-nave">S</div>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div
                    className="tf__footer_content fade_right"
                    data-trigerid="footer"
                    data-stagger=".25"
                    style={{
                      translate: "none",
                      rotate: "none",
                      scale: "none",
                      transform: "translate(0px)",
                      opacity: "1",
                    }}
                  >
                    <div className="text">
                      <h3 className="text-gold">Product links</h3>
                      <ul className="p-0 px-2">
                        <li>
                          <Link
                            to="/recharge"
                            className="text_hover_animaiton d-flex tex-animate"
                          >
                            <div className="text-nave menu-text">
                              <div className="text-nave">R</div>
                              <div className="text-nave">E</div>
                              <div className="text-nave">C</div>
                              <div className="text-nave">H</div>
                              <div className="text-nave">A</div>
                              <div className="text-nave">R</div>
                              <div className="text-nave">G</div>
                              <div className="text-nave">E</div>
                            </div>
                          </Link>
                        </li>
                        <li className="mt-3">
                          <Link
                            to="/bill_payment"
                            className="text_hover_animaiton d-flex tex-animate"
                          >
                            <div className="text-nave menu-text">
                              <div className="text-nave">B</div>
                              <div className="text-nave">I</div>
                              <div className="text-nave">L</div>
                              <div className="text-nave">L</div>
                              <div
                                className="text-nave"
                                style={{ width: "5px" }}
                              >
                                {}
                              </div>
                              <div className="text-nave">P</div>
                              <div className="text-nave">A</div>
                              <div className="text-nave">Y</div>
                              <div className="text-nave">M</div>
                              <div className="text-nave">E</div>
                              <div className="text-nave">N</div>
                              <div className="text-nave">T</div>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div
                    className="tf__footer_content fade_right"
                    data-trigerid="footer"
                    data-stagger=".25"
                    style={{
                      translate: "none",
                      rotate: "none",
                      scale: "none",
                      transform: "translate(0px)",
                      opacity: "1",
                    }}
                  >
                    <div className="text">
                      <h3 className="text-gold">Send us email</h3>
                      <Link href="mailto:sell@trnxact.com">
                        sell@trnxact.com
                      </Link>
                      <Link href="mailto:info@trnxact.com">
                        info@trnxact.com
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="tf__footer_content fade_right"
                    data-trigerid="footer"
                    data-stagger=".25"
                    style={{
                      translate: "none",
                      rotate: "none",
                      scale: "none",
                      transform: "translate(0px)",
                      opacity: "1",
                    }}
                  >
                    <div className="text">
                      <h3 className="text-gold">Contact Us</h3>
                      <p>
                        TKJS Fintech Private Limited Shop No 10, B Wing, Anant,
                        Sai Apt, Diva, Thane, Thane- 400612, Maharashtra
                      </p>
                      <p>
                        Contact No. <b>9137427028</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="tf__footer_copyright">
                    <p>© Trnxact 2024 | All Rights Reserved</p>
                    <ul>
                      <li>
                        <Link
                          to="/terms"
                          className="text_hover_animaiton d-flex"
                        >
                          <div className="text-nave menu-text">
                            <div className="text-nave">T</div>
                            <div className="text-nave">r</div>
                            <div className="text-nave">a</div>
                            <div className="text-nave">m</div>
                            <div className="text-nave">s</div>
                          </div>
                          <div
                            className="text-nave menu-text"
                            style={{ marginLeft: "5px" }}
                          >
                            <div className="text-nave">&amp;</div>
                          </div>
                          <div
                            className="text-nave menu-text"
                            style={{ marginLeft: "5px" }}
                          >
                            <div className="text-nave">C</div>
                            <div className="text-nave">o</div>
                            <div className="text-nave">n</div>
                            <div className="text-nave">d</div>
                            <div className="text-nave">i</div>
                            <div className="text-nave">t</div>
                            <div className="text-nave">i</div>
                            <div className="text-nave">o</div>
                            <div className="text-nave">n</div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/privacy"
                          className="text_hover_animaiton d-flex"
                        >
                          <div className="text-nave menu-text">
                            <div className="text-nave">P</div>
                            <div className="text-nave">r</div>
                            <div className="text-nave">i</div>
                            <div className="text-nave">v</div>
                            <div className="text-nave">a</div>
                            <div className="text-nave">c</div>
                            <div className="text-nave">y</div>
                          </div>{" "}
                          <div
                            className="text-nave menu-text"
                            style={{ marginLeft: "5px" }}
                          >
                            <div className="text-nave">P</div>
                            <div className="text-nave">o</div>
                            <div className="text-nave">l</div>
                            <div className="text-nave">i</div>
                            <div className="text-nave">c</div>
                            <div className="text-nave">y</div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/refund"
                          className="text_hover_animaiton d-flex"
                        >
                          <div className="text-nave menu-text">
                            <div className="text-nave">R</div>
                            <div className="text-nave">e</div>
                            <div className="text-nave">f</div>
                            <div className="text-nave">u</div>
                            <div className="text-nave">n</div>
                            <div className="text-nave">d</div>
                          </div>{" "}
                          <div
                            className="text-nave menu-text"
                            style={{ marginLeft: "5px" }}
                          >
                            <div className="text-nave">C</div>
                            <div className="text-nave">a</div>
                            <div className="text-nave">n</div>
                            <div className="text-nave">c</div>
                            <div className="text-nave">e</div>
                            <div className="text-nave">l</div>
                            <div className="text-nave">l</div>
                            <div className="text-nave">a</div>
                            <div className="text-nave">t</div>
                            <div className="text-nave">i</div>
                            <div className="text-nave">o</div>
                            <div className="text-nave">n</div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
