import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import ServicePagesSection from "../components/ServicePagesSection";

const BillPayment = () => {
  return (
    <>
      <Breadcrumb title="Bill Payment" />
      <ServicePagesSection
        subtitle="Utility Bill Payments"
        img="images/1.jpg"
        subtitle2="FASTag Recharge"
        details="Electricity, water, gas—pay all your utility bills conveniently through our platform. No more late fees or service interruptions."
        img2="images/2.jpg"
        details2="Simplify your toll payments with FASTag recharges. Drive through toll booths seamlessly."
      />
    </>
  );
};

export default BillPayment;
