import React from "react";
import Headin from "./Headin";

const Contact = () => {
  return (
    <>
      <section className="tf__contact pt_110 xs_pt_70 pb_120 xs_pb_80">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <Headin
                title="CONTACT"
                subtitle="Get In Touch"
                description="Let’s discussab out an project !"
              />
            </div>
            <div className="col-xl-7 col-lg-7">
              <div className="tf__contact_form_area">
                <form action="#">
                  <div className="row">
                    <div className="col-xl-6">
                      <input type="text" placeholder="Your Name*" />
                    </div>
                    <div className="col-xl-6">
                      <input type="email" placeholder="Your Email*" />
                    </div>
                    <div className="col-xl-6">
                      <input type="text" placeholder="Phone Number*" />
                    </div>
                    <div className="col-xl-6">
                      <input type="text" placeholder="Your Website*" />
                    </div>
                    <div className="col-xl-12">
                      <textarea
                        rows="7"
                        placeholder="Write Your Message Here*"
                      ></textarea>
                      <button className="common_btn" type="submit">
                        Send Mail
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5">
              <div className="col-md-12">
                <div
                  className="tf__design_counter fade_left mt-0"
                  style={{ textAlign: "left" }}
                  data-trigerid="counter"
                >
                  <h4 className="text-gold">Address :</h4>
                  <p>
                    TKJS Fintech Private Limited Shop No 10, B Wing, Anant, Sai
                    Apt, Diva, Thane, Thane- 400612, Maharashtra
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="tf__design_counter fade_left"
                  style={{ textAlign: "left" }}
                  data-trigerid="counter"
                >
                  <h4 className="text-gold">Mail Us :</h4>
                  <p>info@trnxact.com</p>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="tf__design_counter fade_left"
                  style={{ textAlign: "left" }}
                  data-trigerid="counter"
                >
                  <h4 className="text-gold">Telephone :</h4>
                  <p>+91 9137427028</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
